import MPPContainer from '../mpp-container/container';
import SyfAjax from '../Util/SyfAjax';
import SyfUILogger from '../Util/SyfUILogger';
import {isNotEmpty, isEmpty, createFormFieldsForJson, createInputField, getSeamlessApplyBuyCookie} from '../Util/CommonUtils';
import config from '../configuration/configuration';

class MSFIntegration extends MPPContainer{
    constructor(analytics) {
        super(analytics);
        this.callbackMessage = null;
        this.sessionExpCallbackFlag = null;
        this.sessionExpFailUrl = null;
        this.dbpostBackUrl = null;
        this.dbPostBackType = null;
        this.b2CPostback = null;
        this.coreMerchantTokenId = null;
        this.syfUILogger = new SyfUILogger();
        this.merchantData = null;
        this.registerMsfBUYEventListener(this);
        this.partnerId = null;
        this.productType=null;
    }

    msfBuyEventListener = (event) => {
        if (typeof event.data == 'object') {
            let totalHeight = event.data.totalHeight;
            // this code has been added for displaying 100% in
            // case of mobile device
            let parentHeight = window.parent.innerHeight - 20;
            let parentWidth = window.parent.innerWidth;

            if (parentWidth <= 767
                && totalHeight < parentHeight) {
                totalHeight = parentHeight;
            }
            if (isNotEmpty(totalHeight)) {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = totalHeight + 'px';
            }
            this.callbackMessage = event.data.callbackMessage;
            if (event.data.coreMerchantTokenId) {
                this.coreMerchantTokenId = event.data.coreMerchantTokenId;
            }
            if (event.data.sessionExp) {
                this.sessionExpCallbackFlag = event.data.sessionExpCallbackFlag;
                this.sessionExpFailUrl = event.data.sessionExpFailUrl;
                this.dbPostBackType = event.data.dbPostBackType;
                this.dbpostBackUrl = event.data.dbpostBackUrl;
                this.b2CPostback = event.data.b2CPostback;
            }
        } else if (typeof event.data == 'string') {
            if (event.data == 'Close Model') {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = '530px';
                this.closeMSFModalUI();
            } else if (event.data == 'stopSpinner') {
                document.getElementById('iframeMPPloading').style.display = 'none';
            } else if (event.data == 'closeModal') {
                this.closeMSFModal();
            }else if (event.data == 'closeNotReq') {
                this.hideCloseButton();
            }else if (event.data == 'destroy-msf-session') {
                document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = '530px';
                this.closeMSFModalUI();
                this.destroySyfToken();
            }
        }
    }

    hideCloseButton = () => {
        document.getElementById("syfMPPCrossButton")[0].style.display = "none";

    }

    registerMsfBUYEventListener() {
        window.addEventListener("message", this.msfBuyEventListener, false);
    }

    unregisterMsfBuyEventListener() {
        window.removeEventListener("message", this.msfBuyEventListener, false);
    }

    callLenderBuyProcess = (formObj, passedJsonObj) => {
        try {
            this.openMSFModal(formObj, passedJsonObj);
        } catch (exception) {
            console.error(exception);
            this.syfUILogger.sendLogs(exception);
        }
    }

    mppModalElementTemplate = () => {
        let modalTemplate = '<div class="syf-mpp-modal syf-mpp-fade syf-mpp-in" id="mppModal" role="dialog" tabindex="-1" '
        + 'aria-labelledby="MPP Modal">'
        + '<div class="syf-mpp-modal-dialog" role="document">'
        + '<div class="syf-mpp-modal-content"><div class="syf-mpp-chng-height">'
        + '<div id="iframeMPPloading" class="syf-mpp-loading">'
        + '<img id="syfMPPLoadingImage" alt="loading" class="syf-mpp-loading-image"/></div>'
        + '<div class="syfMPPClose-bt-full"  id="syf-mpp-close-btn-new">'
        + '<div tabindex="0" id="syfMPPCrossButton"'
        + '	class="syf-mpp-close" aria-label="Close"'
        + '	onclick="syfMPP.mppProduct.activeProduct.closeMSFModal()" onkeypress="syfMPP.mppProduct.activeProduct.closeModalWithKeyboard(event)"'
        + '	>'
        + '	<img alt="close" id="syfMPPModalCloseBtn"  src="'
        + config[config.env].syfXIamageUL
        + '" class="syf-mpp-modal-XImage">'
        + '</div>'
        + '</div>'
        + '<iframe id="mppFrame" name="mppFrame" title="SYF MultiProduct Platform" frameborder="0px" style="width:100%;height:100%;border:none;"></iframe></div></div></div></div>';
        return modalTemplate;
    }

    getToken =(transactionToken, tokenId) =>{
        if(transactionToken!="" && transactionToken!=undefined)
            return transactionToken;
        else if (tokenId != "" && tokenId != undefined)
            return tokenId
    }

    openMSFModal = (formObj, passedJsonObj) =>{
        formObj = createFormFieldsForJson(formObj, passedJsonObj);
        console.log("form object..."+formObj);
        console.log("opening MSF Modal...");

        let url = config[config.env].syfMPPModalURL['msfCheckout'];
        let syfToken;
        let partnerId;
        if (passedJsonObj != null) {
            partnerId = passedJsonObj.syfPartnerId;
            this.syfUILogger.tokenID = this.getToken(passedJsonObj.transactionToken, passedJsonObj.tokenId);
        } else {
            partnerId = formObj.syfPartnerId.value;
            this.syfUILogger.tokenID = this.getToken(formObj.transactionToken.value, formObj.tokenId.value);
        }
        this.partnerId = partnerId;

        getSeamlessApplyBuyCookie(partnerId, (syfToken) => {
            if (passedJsonObj != null) {
                // url += "?tokenId=" + passedJsonObj.tokenId;
                if(passedJsonObj.tokenId!="" && passedJsonObj.tokenId != undefined){
                    url += "?tokenId=" + passedJsonObj.tokenId;
                    this.syfUILogger.tokenID = passedJsonObj.tokenId;
                }else if(passedJsonObj.transactionToken!="" && passedJsonObj.transactionToken!=undefined){
                    url += "?transactionToken=" + passedJsonObj.transactionToken;
                    this.syfUILogger.tokenID = passedJsonObj.transactionToken;
                }else{
                    url += "?tokenId=" + passedJsonObj.tokenId;
                    this.syfUILogger.tokenID = passedJsonObj.tokenId;
                }
                url += "&partnerId=" + partnerId;
                url += "&childMid=" + passedJsonObj.childMid;
                // this.syfUILogger.tokenID = passedJsonObj.tokenId;
            }
    
            let modalElementId = config[config.env].syfMppModalHolderId; //Check if we should use "msf_modal" instead of "mpp_modal"
            //check if modal id does not exist
            if (!document.getElementById(modalElementId)) {
                let modalDiv = document.createElement("div");
                modalDiv.id = modalElementId;
                document.body.appendChild(modalDiv);
            }

            if (document.getElementsByName("syfToken").length > 0) {
                //  formObj.removeChild(document.getElementById("syfToken"));
                document.getElementById("syfToken").remove();
            }
            formObj.appendChild(createInputField("hidden", "syfToken", "syfToken", syfToken));
    
            let modalTemplate = this.mppModalElementTemplate();
            this.loadModalInDOM(modalElementId, modalTemplate);
            this.modalActionOnDOMAttach(formObj, url);
            let mppFrame = document.getElementById("mppFrame");
            // if(mppFrame != undefined)
            //     mppFrame.src="http://localhost:3002/msf-ui"; //Getting hold of iFrame and loading the local msf-ui app in that for testing
                    
            
            if(document.getElementsByClassName('syf-mpp-chng-height')){
                if((window.parent.innerHeight - 20)<700){
                    document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.innerHeight + 'px';
                }else{
                  document.getElementsByClassName('syf-mpp-chng-height')[0].style.height = window.parent.innerHeight - 20+'px';
                }
            }
        });

        //Later, add the logic to pass the form json to the mpp-core and load the msf-ui from there instead of direct loading
    }

    closeMSFModal = () =>{
        console.log("closeMSFModal...");
        var sessionExpCallbackFlag=
        (isNotEmpty(this.sessionExpCallbackFlag)) ? this.sessionExpCallbackFlag
               : "";
        var sessionExpFailUrl=
                (isNotEmpty(this.sessionExpFailUrl)) ? this.sessionExpFailUrl : "";
        var dbpostBackUrl=
                (isNotEmpty(dbpostBackUrl)) ? dbpostBackUrl : "";
        var dbPostBackType=
                (isNotEmpty(dbPostBackType)) ? dbPostBackType : "";
        var b2CPostback=
                (isNotEmpty(b2CPostback)) ? b2CPostback : "";
        var tokenIdd=
                (isEmpty(this.syfUILogger.tokenID)) ? this.coreMerchantTokenId : this.syfUILogger.tokenID;
        var requestOrigin = "mpp";
        var partnerId = this.partnerId;
        var productType = this.productType;   
      
       const requestOptions={
               method: 'POST',
         	   mode:'no-cors',
               headers:{
                'Content-Type':'application/json'
                },
               body:JSON.stringify( {
                   sessionExpCallbackFlag,
                   sessionExpFailUrl,
                   dbpostBackUrl,
                   dbPostBackType,
                   b2CPostback,
                   tokenIdd,
                   requestOrigin,
                   partnerId,
                   productType
               })
           }
               fetch(config[config.env].msfCloseButtonUrl ,requestOptions )
                   //.then(response => response.json())
                   .then(res => {
                    // let response = JSON.parse(res);
                    // this.callbackMessage = response.callbackMessage;
                    window.postMessage("Close Model", "*");
                    // this.destroySyfToken();
                    // if (isNotEmpty(response.bnplTransactionCompleted) && response.bnplTransactionCompleted) {
                    //     this.destroySyfToken();
                    // }
                    // if (isNotEmpty(response.authenticationFailUrl)) {
                    //     this.authFailRedirect(response.authenticationFailUrl,
                    //         response.callbackMessage);
                    // }
                    this.sessionExpCallbackFlag = null;
                    this.sessionExpFailUrl = null;
                    this.dbPostBackType = null;
                    this.dbpostBackUrl = null;
                    this.b2CPostback = null; 
                   });
    }

    closeMSFModalUI = () =>{
        console.log("closeMSFModalUI...");
        this.unifyCloseModalEvent();
        this.closeMSFModalWindow();
        this.unregisterMsfBuyEventListener();
        if (isNotEmpty(this.callbackMessage)) {
            try {
                if (document.getElementById("callbackMessage") !== null && document.getElementById("callbackMessage") !== null) {
                    document.getElementById("callbackMessage").innerHTML = this.callbackMessage;
                }
                this.passJsonCallBackToMerchant(this.callbackMessage);


            } catch (exception) {
                console.error(exception);
            }
        }
    }

    closeMSFModalWindow = () =>{
        this.windowObject.postMessage(
            JSON.stringify({ event: "widget-content-refresh" }),
            "*"
          );
        document.getElementById("mppModal").style.display = "none";
        document.getElementsByClassName("syf-mpp-modal-open")[0].style.position =
          "relative";
        document.body.classList.remove("syf-mpp-modal-open");
      }

      modalActionOnDOMAttach = (form, url) => {
        form.target = "mppFrame";
        form.action = url;
        form.method = "POST";
        form.submit();
    }

    passJsonCallBackToMerchant = (callbackMessage) => {
        let syfAjax = new SyfAjax();
        syfAjax.get("parseDbuyJsonCallBack.do?callbackMessage="
            + callbackMessage, "", (err, data) => {
                if (err || data.status === 400 || data.status === 404) {
                    let logDesc = err;
                    console.error(logDesc);
                    if (data.status === 400 || data.status === 404) {
                        logDesc = "Failed 400 " + data.responseURL;
                    }
                    console.error(logDesc);
                    this.syfUILogger.sendLogs(logDesc);
                }
                syfAjax = null;
            });
    }
}

export default MSFIntegration;
