const configuration = {
    dev: {
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://dpdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://dpdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        msfCloseButtonUrl:"https://dpdpone.syfpos.com/msfbuyservice/api/v1/closeModal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles.css as well
        syfMPPModalURL: {
            'pdp': "https://dpdpone.syfpos.com/mpp/mpp",
            'checkout': "https://dpdpone.syfpos.com/mppcore/mppcheckout",
            'msfCheckout': "https://dpdpone.syfpos.com/mppcore/msfCheckout"
        },
        syfTokenRemoveUrl: "https://dpdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        widgeturl: "https://widget-domain-service-dev.app.dev.usw2.pcf.syfbank.com",
        getConfigData: "https://dpdpone.syfpos.com/mppcore/getConfigData"

    },
    dev1: {
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://dpdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://dpdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        msfCloseButtonUrl:"https://dpdpone.syfpos.com/msfbuyservice/api/v1/closeModal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles.css as well
        syfMPPModalURL: {
            'pdp': "https://dpdpone.syfpos.com/mpp/mpp",
            'checkout': "https://dpdpone.syfpos.com/mppcore/mppcheckout",
            'msfCheckout': "https://dpdpone.syfpos.com/mppcore/msfCheckout"
        },
        syfTokenRemoveUrl: "https://dpdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        widgeturl: "https://dwidgets.syfpos.com",
        getConfigData: "https://dpdpone.syfpos.com/mppcore/getConfigData"

    },
    qa: {

        syfDomain: "https://pos.qbuy.syf.com/",
        syfMPPURL: "https://qpdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://qpdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://qpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://qpdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        msfCloseButtonUrl:"https://qpdpone.syfpos.com/msfbuyservice/api/v1/closeModal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://qpdpone.syfpos.com/mpp/mpp",
            'checkout': "https://qpdpone.syfpos.com/mppcore/mppcheckout",
            'msfCheckout': "https://qpdpone.syfpos.com/mppcore/msfCheckout"
        },
        syfTokenRemoveUrl: "https://qpdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/qa/utag.js",
        widgeturl: "https://qwidgets.syfpos.com",
        getConfigData: "https://qpdpone.syfpos.com/mppcore/getConfigData"

    },
    uat: {
        syfDomain: "https://pos.ubuy.syf.com/",
        syfMPPURL: "https://updpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://updpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://qpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://updpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://updpone.syfpos.com/mpp/mpp",
            'checkout': "https://updpone.syfpos.com/mppcore/mppcheckout"
        },
        syfTokenRemoveUrl: "https://updpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/qa/utag.js",
        widgeturl: "https://uwidgets.syfpos.com",
        getConfigData: "https://updpone.syfpos.com/mppcore/getConfigData"

    },
    stg: {
        syfDomain: "https://pos.sbuy.syf.com/",
        syfMPPURL: "https://spdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://spdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://spdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://spdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://spdpone.syfpos.com/mpp/mpp",
            'checkout': "https://spdpone.syfpos.com/mppcore/mppcheckout"
        },
        syfTokenRemoveUrl: "https://spdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/qa/utag.js",
        widgeturl: "https://swidgets.syfpos.com",
        getConfigData: "https://spdpone.syfpos.com/mppcore/getConfigData"

    },
    prf: {
        syfDomain: "https://pos.pbuy.syf.com/",
        syfMPPURL: "https://ppdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://ppdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://qpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://ppdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://ppdpone.syfpos.com/mpp/mpp",
            'checkout': "https://ppdpone.syfpos.com/mppcore/mppcheckout"
        },
        syfTokenRemoveUrl: "https://ppdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        widgeturl: "https://pwidgets.syfpos.com",
        getConfigData: "https://ppdpone.syfpos.com/mppcore/getConfigData"

    },
    dal1: {
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://mpp-dev.app.dev1.dal.pcf.syfbank.com/mpp/",
        syfMPPCoreURL: "https://mpp-dev.app.dev1.dal.pcf.syfbank.com/mppcore/",
        syfXIamageUL: "https://qpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://qpdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://mpp-dev.app.dev1.dal.pcf.syfbank.com/mpp",
            'checkout': ""
        },
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "/DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        getConfigData: "https://dpdpone.syfpos.com/mppcore/getConfigData"

    }, phx: {
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://mpp-dev.app.dev.phx.pcf.syfbank.com/mpp/",
        syfMPPCoreURL: "https://mpp-dev.app.dev.phx.pcf.syfbank.com/mppcore/",
        syfXIamageUL: "https://pdpone.syfpayments.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://pdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://mpp-dev.app.dev.phx.pcf.syfbank.com/mpp",
            'checkout': ""
        },
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "/DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        getConfigData: "https://dpdpone.syfpos.com/mppcore/getConfigData"

    },
    prod: {
        syfDomain: "https://buy.syf.com/",
        syfMPPURL: "https://pdpone.syfpayments.com/mpp/",
        syfMPPCoreURL: "https://pdpone.syfpayments.com/mppcore/",
        syfXIamageUL: "https://pdpone.syfpayments.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://pdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles
        syfMPPModalURL: {
            'pdp': "https://pdpone.syfpayments.com/mpp/mpp",
            'checkout': "https://pdpone.syfpayments.com/mppcore/mppcheckout"
        },
        syfTokenRemoveUrl: "https://pdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/prod/utag.js",
        widgeturl: "https://widgets.syfpayments.com",
        getConfigData: "https://pdpone.syfpayments.com/mppcore/getConfigData"

    },

    use1: {
        //for temp purpose using dev env urls for use1
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://dpdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://dpdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        msfCloseButtonUrl:"https://msfdbuy-dpos-dev.app.dev1.use1.pcf.syfbank.com/msfbuyservice/api/v1/closeModal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles.css as well
        syfMPPModalURL: {
            'pdp': "https://dpdpone.syfpos.com/mpp/mpp",
            'checkout': "https://dpdpone.syfpos.com/mppcore/mppcheckout",
            'msfCheckout': "https://dpdpone.syfpos.com/mppcore/msfCheckout"
        },
        syfTokenRemoveUrl: "https://dpdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        widgeturl: "https://dwidgets.syfpos.com",
        getConfigData: "https://dpdpone.syfpos.com/mppcore/getConfigData"
    },
    usw2: {
        //for temp purpose using dev env urls for usw2
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://dpdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://dpdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        msfCloseButtonUrl:"https://msfdbuy-dpos-dev.app.dev1.use1.pcf.syfbank.com/msfbuyservice/api/v1/closeModal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles.css as well
        syfMPPModalURL: {
            'pdp': "https://dpdpone.syfpos.com/mpp/mpp",
            'checkout': "https://dpdpone.syfpos.com/mppcore/mppcheckout",
            'msfCheckout': "https://dpdpone.syfpos.com/mppcore/msfCheckout"
        },
        syfTokenRemoveUrl: "https://dpdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        widgeturl: "https://dwidgets.syfpos.com",
        getConfigData: "https://dpdpone.syfpos.com/mppcore/getConfigData"
    },
    dev2: {
        //for temp purpose using dev env urls for usw2
        syfDomain: "https://pos.dbuy.syf.com/",
        syfMPPURL: "https://d2pdpone.syfpos.com/mpp/",
        syfMPPCoreURL: "https://d2pdpone.syfpos.com/mppcore/",
        syfXIamageUL: "https://dpdpone.syfpos.com/cs/groups/public/documents/et_clientoffer/e070441.svg",
        syfLoadingImageURL: "/digitalbuy/images/ajax-loader.gif",
        syfLogoURL: "https://d2pdpone.syfpos.com/cs/groups/public/documents/et_imagetype/etimg095652.gif",
        syfCssFileLocation: "syfMPPStyles.css",
        syfIFrameStyles: "syfIframeStyles.css",
        syfIframeModalHolderId: "syfIframe_modal",
        msfCloseButtonUrl:"https://msfdbuy-dpos-dev.app.dev1.use1.pcf.syfbank.com/msfbuyservice/api/v1/closeModal",

        syfMppModalHolderId: "mpp_modal",//change the reference in syfMPPModalStyles.css as well
        syfMPPModalURL: {
            'pdp': "https://d2pdpone.syfpos.com/mpp/mpp",
            'checkout': "https://d2pdpone.syfpos.com/mppcore/mppcheckout",
            'msfCheckout': "https://dpdpone.syfpos.com/mppcore/msfCheckout"
        },
        syfTokenRemoveUrl: "https://d2pdpone.syfpos.com/mppcore/token/remove/",
        syfDBuyURL: "/DigitalBuy/processCart.do",
        syfFetchMerchantURL: "/DigitalBuy/merchantType.do",
        syfCloseButtonURL: "DigitalBuy/closeModal.do",
        adobeAnalyticsUtag: "//tags.syf.com/syf-unifi-spa/dev/utag.js",
        widgeturl: "https://d2widget.syfpos.com",
        getConfigData: "https://d2pdpone.syfpos.com/mppcore/getConfigData"
    },
    env: "prod"
}
export default configuration;
